import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <div className="bg-dark-bg text-white min-h-screen p-6">
      <h2 className="text-3xl font-bold mb-6">Welcome to Your Dashboard</h2>

      <div className="grid grid-cols-2 gap-6">
        <div className="bg-secondary p-6 rounded-lg shadow-lg hover:bg-primary transition-all">
          <h3 className="text-xl mb-2">License Usage</h3>
          <p>Your current license usage this month: 5 devices</p>
          <Link to="/license" className="text-primary hover:underline">View Details</Link>
        </div>

        <div className="bg-secondary p-6 rounded-lg shadow-lg hover:bg-primary transition-all">
          <h3 className="text-xl mb-2">Payment Methods</h3>
          <p>Your payment method is linked.</p>
          <Link to="/payment" className="text-primary hover:underline">Update Payment</Link>
        </div>

        <div className="bg-secondary p-6 rounded-lg shadow-lg hover:bg-primary transition-all">
          <h3 className="text-xl mb-2">Devices Registered</h3>
          <p>You have 5 devices registered.</p>
          <Link to="/devices" className="text-primary hover:underline">Manage Devices</Link>
        </div>

        <div className="bg-secondary p-6 rounded-lg shadow-lg hover:bg-primary transition-all">
          <h3 className="text-xl mb-2">Account Settings</h3>
          <Link to="/settings" className="text-primary hover:underline">Update Account</Link>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
