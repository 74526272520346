import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post('https://asma.alsopss.com/api/login', {
        email,
        password,
      });

      if (response.data.success) {
        // Store the token or session in localStorage or context
        localStorage.setItem('authToken', response.data.token);
        navigate('/dashboard'); // Redirect to the dashboard on successful login
      } else {
        setError('Invalid credentials.');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-bgColor text-white">
      <div className="bg-mps p-8 rounded-lg w-96 shadow-lg">
        <h2 className="text-2xl font-bold mb-4 text-center text-primary">Login</h2>
        <input
          type="email"
          placeholder="Email"
          className="w-full p-2 mb-4 bg-mpsp border-2 border-gray-500 rounded"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          className="w-full p-2 mb-4 bg-mpsp border-2 border-gray-500 rounded"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        <button
          className="w-full p-2 bg-secondary rounded text-white"
          onClick={handleLogin}
        >
          Login
        </button>
        <div className="text-center mt-4">
          <Link to="/signup" className="text-primary hover:underline">
            Don't have an account? Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
